import { html } from 'lit';
import '@tjmonsi/small-router/small-router.js';
import './styles/index.scss';
import { getUser } from './utils/firebase/user.js';

const el = /** @type {*} */(document.querySelector('small-router'));

const checkIfUser = async () => {
  const user = await getUser();
  if (!user) {
    const lastUrl = new URL(window.location.href);
    el.changeUrl(`/loading?redirect=${encodeURI(lastUrl.pathname)}`);
  }
};

// el.addEventListener('route-change-error', /** @param {*} param1 */function ({ detail }) {
//   console.log('router error');
//   console.log(detail);
// });

const url = '';

el.routes = {
  '/': {
    render: () => html`<page-login></page-login>`,
    preRender: () => import('./pages/page-login/index.js')
  },
  '/loading': {
    render: () => html`<page-loading></page-loading>`,
    preRender: () => import('./pages/page-loading/index.js')
  },
  '/analytics': { // done GET
    render: () => html`<page-analytics action='${url}/dev/analytics'></page-analytics>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-analytics/index.js')
    ]
  },
  '/crawler': { // done GET
    render: () => html`<page-searchable-domains action='${url}/dev/domains'></page-searchable-domains>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-searchable-domains/index.js')
    ]
  },
  '/crawler/searchable-domains': { // done GET
    render: () => html`<page-searchable-domains action='${url}/dev/domains'></page-searchable-domains>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-searchable-domains/index.js')
    ]
  },
  '/crawler/urls': { // done GET
    render: () => html`<page-urls action='${url}/dev/page-crawl'></page-urls>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-urls/index.js')
    ]
  },
  '/crawler/blocked-keywords': { // done GET
    render: () => html`<page-blocked-keywords action='${url}/dev/blocked-keyword'></page-blocked-keywords>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-blocked-keywords/index.js')
    ]
  },
  '/crawler/blocked-urls': { // done GET
    render: () => html`<page-blocked-urls action='${url}/dev/blocked-url'></page-blocked-urls>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-blocked-urls/index.js')
    ]
  },
  '/page-management': { // done GET
    render: () => html`<page-indexed-pages action='${url}/dev/pages'></page-indexed-pages>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-indexed-pages/index.js')
    ]
  },
  '/page-management/indexed-pages': { // done GET
    render: () => html`<page-indexed-pages action='${url}/dev/pages'></page-indexed-pages>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-indexed-pages/index.js')
    ]
  },
  '/page-management/blocked-pages': { // done GET
    render: () => html`<page-blocked-pages action='${url}/dev/pages'></page-blocked-pages>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-blocked-pages/index.js')
    ]
  },
  '/logout': {
    render: () => html`<page-logout></page-logout>`,
    preRender: [
      checkIfUser,
      () => import('./pages/page-logout/index.js')
    ]
  },
  'not-found': {
    render: () => html`<page-not-found></page-not-found>`,
    preRender: [
      () => import('./pages/page-not-found/index.js')
    ]
  }
};

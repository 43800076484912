import { initializeApp } from 'firebase/app';

const apiKey = process.env.FIREBASE_API_KEY;
const authDomain = process.env.FIREBASE_AUTH_DOMAIN;

const config = {
  apiKey,
  authDomain
};

export const app = initializeApp(config);

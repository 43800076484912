import { app } from './index.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

let onAuthStateChangedInit = false;

export const auth = getAuth(app);

onAuthStateChanged(auth, () => {
  /** @type {*} */
  // const el = document.querySelector('small-router');
  // if (el) {
  //   if (!user) {
  //     // el.changeUrl('/');
  //   }
  // }

  onAuthStateChangedInit = true;
});

/**
 *
 * @param {number} timeout
 * @returns {Promise<*>}
 */
const delay = async (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

// @ts-ignore
export const getUser = async () => {
  if (onAuthStateChangedInit) {
    return auth.currentUser;
  }

  await delay(2000);

  return getUser();
};
